<template>
  <div>
    <div class="navbar">
      <van-nav-bar
        title="预览错题"
        left-text="返回"
        right-text="打印"
        left-arrow
        @click-left="goBack"
        @click-right="printFn"
      />
    </div>
    <div class="preview">
      <iframe :src="pdfPath" width="100%" height="100%"></iframe>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { Toast } from "vant";
import { prefix, prefixFile } from "@/common/js/utils";
import { apiCreateFile, apiDo } from "@/service/prints.js";

const router = useRouter();
const route = useRoute();

let pdfPath = ref("");
const getPDF = async () => {
  let formData = new FormData();
  formData.append("noStr", route.query.noStr);
  formData.append("urlPath", "printPreview.html");
  let res = await apiCreateFile(formData);

  pdfPath.value =
    res.data.file_ext == "pdf"
      ? prefix(res.data.file_path)
      : prefixFile(prefix(res.data.file_path));
};
getPDF();

const printFn = async () => {
  let formData = new FormData();
  formData.append("noStr", route.query.noStr);
  let res = await apiDo(formData);
  if (res) {
    Toast.success(res.msg);
  }
};

const goBack = () => {
  router.go(-1);
};
</script>

<style lang="less" scoped>
.preview {
  width: 80%;
  height: 80%;
  margin: auto;
}

.navbar {
  margin-bottom: 10px;
}
</style>
